.cards-wrapper {
  display: flex;
  justify-content: center;
}

.card img {
  max-width: 100%;
  max-height: 100%;
}

.homeEventSection {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #B4A1DB;
  flex-direction: row;
  height: 500px;
}

#carouselExampleControls.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px;
  /* Adjusted width */
  height: 400px;
  background-color: #B4A1DB;

  margin-right: 40px;
}

#carouselExampleControls.carousel .carousel-inner {
  height: 400px;
  margin-top: 40px;

  /* Adjust the height as needed */
  background-color: #B4A1DB;

}

#carouselExampleControls.carousel .carousel-inner .carousel-item {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  height: 100%;
  background-color: #B4A1DB;

}

#carouselExampleControls.carousel .carousel-inner .carousel-item img {
  width: 100%;
  /* Set the width to 100% of the parent container */
  height: 300px;
  /* Let the height adjust proportionally */
  max-width: 800px;
  /* Optional: set a maximum width */
  /* max-height: 350px; */
  /* Optional: set a maximum height */
  border-bottom: 10px;
}

.card-img-top {
  width: 100%;
  /* Set the width to 100% of the parent container */
  height: auto;
  /* Let the height adjust proportionally */
  max-width: 800px;
  /* Optional: set a maximum width */
  max-height: 350px;
  /* Optional: set a maximum height */
}

.card {
  margin: 0 0.5em;
  /* box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18); */
  border: none;
  border-radius: 0;
}

.carousel-item {
  background: none;
}

.carousel-inner {
  padding: 1em;
  background: none;
}

.carousel-container {
  padding: 50px;
}

.carousel-control-next-icon {
  align-items: center;
  top: 80px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 150px;
}

.headingContainer h1 {
  font-size: 3em;
  color: white;
  text-align: center;
}

.headingContainer p {
  font-size: 1.5em;
  color: white;
  text-align: center;
}

.headingContainer p:hover {
  color: #F5A962;
}
.headingContainer a{
  text-decoration: none;
} 

@media (max-width: 768px) {
  .homeEventSection {
    flex-direction: column;
    /* Change flex-direction to column for mobile view */
    height: auto;
    /* Set height to auto to adjust based on content */
  }

  #carouselExampleControls.carousel {
    width: 100%;
    /* Set width to 100% for full width on mobile */
    max-width: 400px;
    /* Set max-width to limit the width on smaller screens */
    height: auto;
    /* Set height to auto to adjust based on content */
    margin: 0 auto;
    /* Center the carousel horizontally */
    margin-bottom: 20px;
    /* Add margin at the bottom for spacing */
  }
  #carouselExampleControls.carousel .carousel-inner {
   
    margin-top: 40px;
  
    /* Adjust the height as needed */
    background-color: #B4A1DB;
  
  }
  #carouselExampleControls.carousel .carousel-inner .carousel-item img {
    height: 280px;
    /* Set height to auto to adjust based on content */
    max-width: 100%;
    /* Set max-width to ensure the image fits within the carousel */
  }

  .headingContainer {
    margin: 20px auto;
    /* Center the heading container and add margin */
  }

  .headingContainer h1 {
    font-size: 2em;
    /* Reduce font size for smaller screens */
  }

  .headingContainer p {
    font-size: 1em;
    /* Reduce font size for smaller screens */
  }
  .headingContainer a{
    text-decoration: none;
  }
}