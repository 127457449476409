@import url('https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kablammo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

.facSection {
    margin-left: 40px;
    position: relative;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: start;

    .bagimg {
        position: relative;
        width: 100%;
        height: 400px;
        /* Adjust the height as needed */
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: fill;
            filter: blur(5px);
        }

        p {
            font-family: 'Kablammo', system-ui;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 100px;
            font-weight: bold;
            text-align: center;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
    }


    .mainHeading {
        margin-top: 10px;
        margin-bottom: 10px;

        h1 {
            color: #4f519f;
            font-weight: 600;
        }
    }
    .ourFacilities {
        display: flex;
        font-family: 'Montserrat', sans-serif;

        justify-content: center;
      }
      
      .mainContainer {
        max-width: 1200px;
        margin: 0 auto;
      }
      
      .infrastructure-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 20px;
      }
      
      .infrastructure-item {
        cursor: pointer;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        text-align: center;
        transition: all 0.3s ease;
      }
      
      .infrastructure-item:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
      
      .infrastructure-item h3 {
        margin: 0;
        // color: #4f519f;
        font-size: 20px;
      }
      
      .selected-item-details {
        margin-top: 20px;
      }
      
      .selected-item-details h2 {
        color: #4f519f;
        font-size: 34px;
        margin-bottom: 10px;
      }
      
      .selected-item-details img {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
      }
      
      .selected-item-details p {
        color: #333;
        font-size: 18px;
        line-height: 1.6;
      }
      
      .selected-item-details button {
        padding: 10px 20px;
        background-color: #4f519f;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
      .selected-item-details button:hover {
        background-color: #36387f;
      }


    
}

@media (max-width: 768px) {
    .facSection {
        margin-left: 10px;
        /* Further adjust margin for smaller screens */
        padding-top: 130px;

        .bagimg {
            height: 200px;
            /* Further adjust the height for smaller screens */

            p {
                font-size: 30px;
                /* Further adjust font-size for smaller screens */
            }
        }

        .mainHeading {
            h1 {
                font-size: 30px;
                /* Further adjust font-size for smaller screens */
            }
        }
        .ourFacilities {
            padding: 20px;
          }
        
          .infrastructure-grid {
            grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            gap: 10px;
          }
        
          .infrastructure-item {
            padding: 10px;
            font-size: 16px;
          }
        
          .selected-item-details h2 {
            font-size: 20px;
          }
        
          .selected-item-details img {
            margin-bottom: 20px;
          }
        
          .selected-item-details p {
            font-size: 14px;
          }
        
          .selected-item-details button {
            font-size: 14px;
          }
    }
}