@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.aboutSection {
  display: flex;
  flex-direction: row;
}
.aboutSection a {
  text-decoration: none;
}
.aboutSection .leftSide {
  position: relative;
  margin-top: 50px;
  left: 0;
  width: 550px;
}
.aboutSection .leftSide .main-img {
  filter: blur(1px);
  position: relative;
  top: 0;
  left: 0;
  width: 400px;
  height: 400px;
}
.aboutSection .leftSide .hover-img {
  position: absolute;
  top: 100px;
  left: 250px;
  width: 250px;
  height: 250px;
}
.aboutSection .rightSide h1 {
  font-family: "Nunito", sans-serif;
  font-size: 35px;
  font-weight: 700;
  color: #4f519f;
  margin-top: 40px;
  margin-bottom: 1rem;
}
.aboutSection .rightSide p {
  font-family: "Nunito", sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-left: 150px;
  text-align: left;
  margin-top: 40px;
  margin-right: 60px;
  margin-bottom: 1rem;
  color: rgb(87, 86, 86);
}

@media (max-width: 768px) {
  .aboutSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .aboutSection .leftSide {
    width: 100%;
  }
  .aboutSection .leftSide .main-img {
    filter: blur(0);
    width: 300px;
    height: 300px;
  }
  .aboutSection .leftSide .hover-img {
    display: none;
  }
  .aboutSection .rightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .aboutSection .rightSide h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 30px;
    text-align: center;
  }
  .aboutSection .rightSide p {
    margin-left: 10px;
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    margin-right: 10px;
    margin-bottom: 1rem;
  }
}/*# sourceMappingURL=About.css.map */