.contactContainer {
  position: relative;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.contactContainer .bgimg {
  position: relative;
  width: 100%;
  height: 400px;
  /* Adjust the height as needed */
  overflow: hidden;
}
.contactContainer .bgimg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  filter: blur(5px);
}
.contactContainer .bgimg p {
  font-family: "Kablammo", system-ui;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.mainContactForm {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.contactContainer h1 {
  text-align: center;
  color: #4f519f;
}

.contactContainer p {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type=text],
input[type=email],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.error-message {
  color: #ff0000;
}

.success-message {
  color: #00cc00;
}

button[type=submit] {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type=submit]:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 768px) {
  .contactContainer {
    padding: 10px;
    padding-top: 120px;
  }
}
@media screen and (max-width: 480px) {
  input[type=text],
  input[type=email],
  textarea {
    font-size: 14px;
  }
}/*# sourceMappingURL=Contact.css.map */