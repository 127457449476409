@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap');


.visionSection {
    text-align: left;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: 0 auto;
    padding: 40px;
    padding-top: 140px;
    font-family: 'Montserrat', sans-serif;


    h2 {
        font-size: 35px;
        color: #4f519f;
        margin-bottom: 20px;
    }

    .visContainer {
        margin-top: 20px;

        h3 {
            font-size: 28px;
            color: #333;
            margin-bottom: 10px;
        }

        p {
            font-size: 18px;
            color: #666;
            line-height: 1.5;
        }
    }
}

@media (max-width: 768px) {
    .visionSection {
        width: 100%;
        padding: 20px;
        padding-top: 150px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 20px;
    }

    p {
        font-size: 16px;
    }
}
