@import url("https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap");
.footerSeciton {
  height: 490px;
  background-color: #423A3A;
}
.footerSeciton .fooContainer {
  color: #D5C19F;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 100px;
}
.footerSeciton .fooContainer .detailsfield {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: #D5C19F 1px solid;
  padding-bottom: 20px;
}
.footerSeciton .fooContainer .detailsfield .know h3, .footerSeciton .fooContainer .detailsfield .contact h3, .footerSeciton .fooContainer .detailsfield .collegetime h3, .footerSeciton .fooContainer .detailsfield .follow h3 {
  font-size: 35px;
  font-family: "Rubik Doodle Shadow", system-ui;
}
.footerSeciton .fooContainer .detailsfield .contact {
  width: 300px;
}
.footerSeciton .fooContainer .detailsfield .follow .social-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 20px;
}
.footerSeciton .fooContainer .copyRight {
  margin-top: 30px;
}
.footerSeciton .fooContainer .copyRight .developed {
  font-weight: 700;
}

@media (max-width: 768px) {
  .footerSeciton {
    height: 1100px;
  }
  .footerSeciton .fooContainer {
    margin: 50px;
  }
  .footerSeciton .fooContainer .detailsfield {
    flex-direction: column;
  }
  .footerSeciton .fooContainer .detailsfield .know, .footerSeciton .fooContainer .detailsfield .contact, .footerSeciton .fooContainer .detailsfield .collegetime, .footerSeciton .fooContainer .detailsfield .follow {
    margin-bottom: 30px;
  }
  .footerSeciton .fooContainer .detailsfield .know h3, .footerSeciton .fooContainer .detailsfield .contact h3, .footerSeciton .fooContainer .detailsfield .collegetime h3, .footerSeciton .fooContainer .detailsfield .follow h3 {
    font-size: 25px;
  }
  .footerSeciton .fooContainer .detailsfield .contact {
    width: 100%;
  }
}/*# sourceMappingURL=Footer.css.map */