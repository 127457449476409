
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap');

.ChaiSection {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: 0 auto;
    padding: 40px;
    padding-top: 140px;
    font-family: 'Montserrat', sans-serif;

    .chairImg{
       
        margin-right: 20px;
        img{
            border-radius: 50%;

            width: 300px;
            height: 300px;
        }
    }

}



.chairmanMsg {
    max-width: 800px;
    margin: 0 auto;

    h1 {
        font-size: 36px;
        color: #4f519f;
       
        margin-bottom: 20px;
    }
}

.chairmanMsg h3 {
    font-size: 24px;
    color: #4f4f4f;
    margin-bottom: 10px;
}

.chairmanMsg p {
    font-size: 18px;
    color: #666;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .ChaiSection{
        display: flex;
        flex-direction: column;
        padding: 20px;
        padding-top: 150px;
        .chairImg{
            margin-bottom: 30px;
        }
    }
    .chairmanMsg h1 {
        font-size: 28px;
    }

    
    .chairmanMsg h3 {
        font-size: 20px;
    }

    .chairmanMsg p {
        font-size: 16px;
    }
}
