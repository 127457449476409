@import url('https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kablammo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

.acaSection {
    margin-left: 40px;
    position: relative;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: start;

    .bgimg {
        position: relative;
        width: 100%;
        height: 400px;
        /* Adjust the height as needed */
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: fill;
            filter: blur(5px);
        }

        p {
            font-family: 'Kablammo', system-ui;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 100px;
            font-weight: bold;
            text-align: center;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
    }


    .mainHeading {
        margin-top: 10px;
        margin-bottom: 10px;

        h1 {
            color: #4f519f;
            font-weight: 600;
        }
    }

    .offeredCourses {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        font-weight: 600;

        a {
            text-decoration: none;
            color: #423A3A;
            margin-right: 40px;

        }

        /* Academics.css */
        .offeredCourses a.activeLink {
            color: #423A3A;
            /* Change to your desired color */
            /* Add any other styles for the active link */
        }

        :active {
            color: #423A3A;
        }

        .puc {
            border-right: 1px solid #423A3A;
        }

        p {
            margin-right: 40px;

        }

        :hover {
            color: coral;
        }
    }
}

@media (max-width: 768px) {
    .acaSection {
        margin-left: 10px;
        /* Further adjust margin for smaller screens */
        padding-top: 130px;

        .bgimg {
            height: 200px;
            /* Further adjust the height for smaller screens */

            p {
                font-size: 30px;
                /* Further adjust font-size for smaller screens */
            }
        }

        .mainHeading {
            h1 {
                font-size: 30px;
                /* Further adjust font-size for smaller screens */
            }
        }
    }
}