.resultSection {
  position: relative;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* Adjust width and height of images as needed */
}
.resultSection .bgimg {
  position: relative;
  width: 100%;
  height: 400px;
  /* Adjust the height as needed */
  overflow: hidden;
}
.resultSection .bgimg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: fill;
     object-fit: fill;
  filter: blur(5px);
}
.resultSection .bgimg p {
  font-family: "Kablammo", system-ui;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.resultSection .mainHeading {
  height: 300px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  width: 100%;
  background-color: #d7d4d4;
}
.resultSection .mainHeading .neetRanker,
.resultSection .mainHeading .jeeRanker {
  background-color: #FDFCF2;
}
.resultSection .mainHeading .neetRanker img,
.resultSection .mainHeading .jeeRanker img {
  height: 200px;
  width: 180px;
}
.resultSection .mainHeading .neetRanker p,
.resultSection .mainHeading .jeeRanker p {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
}
.resultSection .mainHeading .heading h1 {
  color: #4f519f;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.resultSection .mainHeading .heading p {
  color: #000;
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.resultSection .result {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  margin-left: 40px;
  /* Center content horizontally */
}
.resultSection .result .r2024 {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.resultSection .result .r2024 h2 {
  font-size: 50px;
  font-weight: bold;
  color: #4f519f;
  text-align: center;
  margin-bottom: 20px;
}
.resultSection .result .r2024 .topranker {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.resultSection .result .r2024 .topranker h4 {
  font-size: 35px;
  margin-bottom: 5px;
  color: #4f519f;
}
.resultSection .result .r2024 .topranker img {
  width: 250px;
  height: auto;
}
.resultSection .result .r2024 .topranker .science {
  margin-right: 50px;
}
.resultSection .result .r2024 .topp img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.resultSection .r2022,
.resultSection .r2023 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* Space items evenly along the main axis */
  margin-top: 20px;
  /* Add margin between rows */
}
.resultSection .Science-2022 img,
.resultSection .Commerce-2022 img,
.resultSection .Science-2023 img,
.resultSection .achivers img {
  max-width: 100%;
  /* Ensure images don't exceed the width of their parent div */
  height: auto;
  /* Maintain aspect ratio */
}

@media (max-width: 1200px) {
  .resultSection .bgimg {
    height: 300px;
  }
  .resultSection .bgimg p {
    font-size: 80px;
  }
  .resultSection .mainHeading {
    height: 200px;
  }
  .resultSection .mainHeading .heading h1 {
    font-size: 70px;
  }
  .resultSection .mainHeading .heading p {
    font-size: 40px;
  }
  .resultSection .mainHeading .neetRanker img,
  .resultSection .mainHeading .jeeRanker img {
    height: 150px;
    width: 130px;
  }
  .resultSection .mainHeading .neetRanker p,
  .resultSection .mainHeading .jeeRanker p {
    font-size: 20px;
  }
  .resultSection .result {
    margin-left: 20px;
    margin-right: 20px;
  }
  .resultSection .result .r2024 h2 {
    font-size: 40px;
  }
  .resultSection .result .r2024 .topranker img {
    width: 230px;
  }
}
@media (max-width: 768px) {
  .resultSection {
    margin-left: 10px;
    /* Further adjust margin for smaller screens */
    padding-top: 130px;
  }
  .resultSection .bgimg {
    height: 200px;
    /* Further adjust the height for smaller screens */
  }
  .resultSection .bgimg p {
    font-size: 30px;
    /* Further adjust font-size for smaller screens */
  }
  .resultSection .mainHeading .heading h1 {
    font-size: 50px;
    /* Further adjust font-size for smaller screens */
  }
  .resultSection .mainHeading .heading p {
    font-size: 30px;
  }
  .resultSection .mainHeading .neetRanker,
  .resultSection .mainHeading .jeeRanker {
    display: none;
  }
  .resultSection .result {
    margin-left: 10px;
    margin-right: 10px;
  }
  .resultSection .r2022,
  .resultSection .r2023 {
    flex-direction: column;
    /* Change to column layout on smaller screens */
    align-items: center;
    /* Center items horizontally */
  }
}
@media (max-width: 600px) {
  .result .r2024 .topranker {
    display: flex !important;
    flex-direction: column !important;
    /* Adjust margin for .science and .commerce */
  }
  .result .r2024 .topranker .science {
    margin-right: 10px !important;
    margin-bottom: 50px;
  }
  .result .r2024 .topranker .science,
  .result .r2024 .topranker .commerce {
    display: flex !important;
    flex-direction: column !important;
  }
  .result .r2024 .topranker .science h4,
  .result .r2024 .topranker .commerce h4 {
    font-size: 25px !important;
    margin-bottom: 5px !important;
    color: #4f519f;
  }
  .result .r2024 .topranker .science img,
  .result .r2024 .topranker .commerce img {
    width: 250px !important;
    height: 300px !important;
  }
  .result .r2024 h2 {
    font-size: 24px !important;
    margin-bottom: 5px !important;
  }
}/*# sourceMappingURL=Result.css.map */