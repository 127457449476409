.facultySection {
  padding: 20px;
  padding-top: 100px;
}
.facultySection .pricipalbio {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  border-radius: 10px;
  margin-left: 35px;
  padding: 20px;
  background: linear-gradient(135deg, #514ea0, #3d5770);
  /* Adjust gradient colors */
  color: #fff;
  width: 600px;
  justify-content: space-between;
}
.facultySection .pricipalbio img {
  height: 350px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.facultySection .pricipalbio p {
  margin: 5px 0;
  font-size: 16px;
}
.facultySection .pricipalbio h3 {
  margin: 0;
  font-size: 20px;
}
.facultySection .pricipalbio .detailsPrincipal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.facultySection h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #514ea0;
}

.fcultyDetails {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.faculty {
  width: 300px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 20px;
  background: linear-gradient(135deg, #514ea0, #3d5770);
  /* Adjust gradient colors */
  color: #fff;
}

.faculty img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

.faculty h3 {
  margin: 0;
  font-size: 20px;
}

.faculty p {
  margin: 5px 0;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .facultySection .pricipalbio {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
  }
  .facultySection .pricipalbio p {
    text-align: center;
  }
  .faculty {
    width: 100%;
  }
}/*# sourceMappingURL=Faculty.css.map */