.carousel-container .carousel-inner .carousel-item {
  height: 400px;
}
.carousel-container, .carousel-inner, .carousel-item, .d-block {
  /* -o-object-fit: fill; */
     object-fit: fill !important;
}
.carousel-container, .carousel-inner, .carousel-item, .carousel-caption {
  background: linear-gradient(to bottom, rgba(255,255,255,0.15) 0%, rgba(0,0,0,0.15) 100%), radial-gradient(at top center, rgba(255,255,255,0.40) 0%, rgba(0,0,0,0.40) 120%) #989898;

}
@media (max-width: 768px) {
  .carousel{
    padding-top: 100px;
  }
  .carousel-container, .carousel-inner, .carousel-item, .d-block {
    height: 400px;
    object-fit: cover;

  }
  .carousel-container .carousel-inner .carousel-item {
    height: 300px;
  }
}
