@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap');

.puSection {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: start;
    margin-left: 40px;
    font-family: 'Montserrat', sans-serif;
    margin-right: 40px;

    .coursesInPu {
        font-size: 20px;
        color: #403f3f;
        margin-bottom: 30px;
        margin-left: 10px;
    }

    .inDetail {
        .part1 {
            margin-bottom: 40px;
            h4 {
                font-size: 20px;
                font-weight: 600;
                color: #4f519f;
            }

            p {
                font-size: 18px;
                color: #403f3f;
                font-weight: 500;
                margin-left: 10px;

            }
        }

        .part2 {
            h4 {
                font-size: 20px;
                font-weight: 600;
                color: #4f519f;
            }

            .container {
                display: flex;
                justify-content: flex-start;
                /* Align items to the left */
                flex-direction: row;
                justify-content: space-between;
                max-width: 100%;
                margin: 0 auto;


                .row {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: start;
                    align-items: start;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 200px;
                }

                .col {
                    flex-basis: calc(25% - 20px);
                    margin-bottom: 20px;
                    cursor: pointer;
                    // text-align: center;
                    transition: transform 0.3s ease-in-out;
                }

                .col:hover {
                    transform: translateY(-5px);
                }

                .overlay {
                    margin-left: 150px;

                }

                img {
                    // max-width: 75%;
                    // max-height: 90%;
                    width: 600px;
                    height: 410px;
                    object-fit: cover;

                }
            }
        }
    }
}

@media (max-width: 768px) {
    .puSection {
        margin-left: 10px;
        margin-right: 10px;
        /* Further adjust margin for smaller screens */
    }

    .container {
        display: flex;
        flex-direction: column !important;

        .overlay {
            margin-left: 0 !important;

            img {
                // max-width: 75%;
                // max-height: 90%;
                width: 280px !important;
                height: 250px !important;
                object-fit: fill !important;

            }
        }



    }
}