.resultSection {
    position: relative;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: start;

    .bgimg {
        position: relative;
        width: 100%;
        height: 400px;
        /* Adjust the height as needed */
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: fill;
            filter: blur(5px);
        }

        p {
            font-family: 'Kablammo', system-ui;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-size: 100px;
            font-weight: bold;
            text-align: center;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        }
    }

    .mainHeading {
        height: 300px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
        text-align: center;
        font-family: 'Quicksand', sans-serif;
        width: 100%;
        background-color: #d7d4d4;

        .neetRanker,
        .jeeRanker {
            background-color: #FDFCF2;

            img {
                height: 200px;
                width: 180px;
            }

            p {
                font-size: 25px;
                font-weight: bold;
                margin-bottom: 0px;
            }

        }

        .heading {
            h1 {
                color: #4f519f;
                font-size: 100px;
                font-weight: bold;
                text-align: center;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
                margin-bottom: 20px;
            }

            p {
                color: #000;
                font-size: 50px;

                font-weight: 600;
                text-align: center;
                text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
            }
        }

    }

    .result {
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        margin-left: 40px;

        /* Center content horizontally */
        .r2024 {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            h2 {
                font-size: 50px;
                font-weight: bold;
                color: #4f519f;
                text-align: center;
                margin-bottom: 20px;
            }

            .topranker {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                h4{
                    font-size: 35px ;
                    margin-bottom: 5px ;
                    color: #4f519f;
                }

                img {
                    width: 250px;
                    height: auto;
                }
                .science{
                    margin-right: 50px;
                }

            }

            .topp {
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .r2022,
    .r2023 {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        /* Space items evenly along the main axis */
        margin-top: 20px;
        /* Add margin between rows */
    }

    /* Adjust width and height of images as needed */
    .Science-2022 img,
    .Commerce-2022 img,
    .Science-2023 img,
    .achivers img {
        max-width: 100%;
        /* Ensure images don't exceed the width of their parent div */
        height: auto;
        /* Maintain aspect ratio */
    }


}

@media (max-width: 1200px) {
    .resultSection {
        .bgimg {
            height: 300px;

            p {
                font-size: 80px;
            }
        }

        .mainHeading {
            height: 200px;

            .heading {
                h1 {
                    font-size: 70px;
                }

                p {
                    font-size: 40px;
                }
            }

            .neetRanker,
            .jeeRanker {
                img {
                    height: 150px;
                    width: 130px;
                }

                p {
                    font-size: 20px;
                }
            }
        }

        .result {
            margin-left: 20px;
            margin-right: 20px;

            .r2024 {
                h2 {
                    font-size: 40px;
                }

                .topranker {

                    img {
                        width: 230px;
                    }
                }

            }
        }
    }

}


@media (max-width: 768px) {
    .resultSection {
        margin-left: 10px;
        /* Further adjust margin for smaller screens */
        padding-top: 130px;

        .bgimg {
            height: 200px;
            /* Further adjust the height for smaller screens */

            p {
                font-size: 30px;
                /* Further adjust font-size for smaller screens */
            }
        }

        .mainHeading {
            .heading {
                h1 {
                    font-size: 50px;
                    /* Further adjust font-size for smaller screens */
                }

                p {
                    font-size: 30px;
                }
            }

            .neetRanker,
            .jeeRanker {
                display: none;
            }

        }

        .result {
            margin-left: 10px;
            margin-right: 10px;


        }

        .r2022,
        .r2023 {
            flex-direction: column;
            /* Change to column layout on smaller screens */
            align-items: center;
            /* Center items horizontally */
        }
    }
}

@media (max-width: 600px) {
    .result .r2024 .topranker {
        display: flex !important;
        flex-direction: column !important;

        /* Adjust margin for .science and .commerce */
        .science {
            margin-right: 10px !important;
            margin-bottom: 50px;
        }

        .science,
        .commerce {
            h4{
                font-size: 25px !important;
                margin-bottom: 5px !important;
                color: #4f519f;
            }
            display: flex !important;
            flex-direction: column !important;

            img {
                width: 250px !important;
                height: 300px !important;
            }
        }
    }

    .result .r2024 h2 {
        font-size: 24px !important;
        margin-bottom: 5px !important;
    }
}
