@import url('https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&display=swap');


.footerSeciton {
    height: 490px;
    background-color: #423A3A;

    .fooContainer {
        color: #D5C19F;

        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 100px;

        .detailsfield {
            margin-top: 50px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: #D5C19F 1px solid;
            padding-bottom: 20px;

            .know, .contact, .collegetime, .follow {
                h3 {
                    font-size: 35px;
                    font-family: 'Rubik Doodle Shadow', system-ui;
                    // font-weight: 700;
                }
            }
            .contact{
                width:300px
            }
            .follow{
                
                .social-icon{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-top: 20px;
                    margin-right: 20px;
                    
                }
            }
        }
        .copyRight{
            .developed{
                font-weight: 700;
            }
            margin-top: 30px;
        }
    }
}
@media (max-width: 768px) {
    .footerSeciton {
        height: 1100px;
        
        .fooContainer {
            margin: 50px;
            .detailsfield {
                flex-direction: column;
                
                .know, .contact, .collegetime, .follow {
                    margin-bottom: 30px;
                    h3 {
                        font-size: 25px;
                    }
                }
                .contact{
                    width: 100%;
                }
            }
        }
    }
}
