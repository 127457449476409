@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500&display=swap");
.puSection {
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  margin-left: 40px;
  font-family: "Montserrat", sans-serif;
  margin-right: 40px;
}
.puSection .coursesInPu {
  font-size: 20px;
  color: #403f3f;
  margin-bottom: 30px;
  margin-left: 10px;
}
.puSection .inDetail .part1 {
  margin-bottom: 40px;
}
.puSection .inDetail .part1 h4 {
  font-size: 20px;
  font-weight: 600;
  color: #4f519f;
}
.puSection .inDetail .part1 p {
  font-size: 18px;
  color: #403f3f;
  font-weight: 500;
  margin-left: 10px;
}
.puSection .inDetail .part2 h4 {
  font-size: 20px;
  font-weight: 600;
  color: #4f519f;
}
.puSection .inDetail .part2 .container {
  display: flex;
  justify-content: flex-start;
  /* Align items to the left */
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
}
.puSection .inDetail .part2 .container .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
}
.puSection .inDetail .part2 .container .col {
  flex-basis: calc(25% - 20px);
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.puSection .inDetail .part2 .container .col:hover {
  transform: translateY(-5px);
}
.puSection .inDetail .part2 .container .overlay {
  margin-left: 150px;
}
.puSection .inDetail .part2 .container img {
  width: 600px;
  height: 410px;
  -o-object-fit: cover;
     object-fit: cover;
}

@media (max-width: 768px) {
  .puSection {
    margin-left: 10px;
    margin-right: 10px;
    /* Further adjust margin for smaller screens */
  }
  .container {
    display: flex;
    flex-direction: column !important;
  }
  .container .overlay {
    margin-left: 0 !important;
  }
  .container .overlay img {
    width: 280px !important;
    height: 250px !important;
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
}/*# sourceMappingURL=pucourses.css.map */