:root {
    --primary-color: #CA4F34;
    --white-color: #ffffff;
    --black-color: #000000;
  }
  

.gallerySection {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    

    .main-heading {
        color: #4f519f;
        font-family: "Inter-Bold", Helvetica;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.7px;
        position: relative;
        white-space: nowrap;
        margin-left: 20px;
        margin-top: 10px;
    }
    .img-section {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    
        .img {
            width: 250px;
            height: 250px;
            object-fit: cover;
            margin: 10px;
            border: 2px solid #ccc;
            border-radius: 10px;
            transition: transform 0.3s;
            cursor: pointer;
    
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    
}


@media (max-width: 768px) {
    .img-section{
        flex-direction: column;
        gap: 1rem;
        // flex-grow: 1fr,2fr,3fr;
        .img {
            height: 250px;
            width: 100%;
        }
    }
    
}